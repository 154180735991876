@font-face {
  font-family: "Frutiger LT Arabic";
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("../fonts/FrutigerLTArabic-45Light.woff") format("truetype");
}

@font-face {
  font-family: "Frutiger LT Arabic";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("../fonts/FrutigerLTArabic-55Roman.woff") format("truetype");
}

@font-face {
  font-family: "Frutiger LT Arabic";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("../fonts/FrutigerLTArabic-65Bold.woff") format("truetype");
}

@font-face {
  font-family: "Frutiger LT Arabic";
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url("../fonts/FrutigerLTArabic-75Black.woff") format("truetype");
}
